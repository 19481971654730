import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"

import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'

import '../styles/gumroad-follow-form.css'

// import frankensteinHeader from '../images/FrankensteinPromo-65.jpg'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    productionsWrapper: {
      paddingBottom: "1em",
      maxWidth: "85rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    showsWrapper: {
      paddingBottom: "1em",
      maxWidth: "60rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    button: {
      margin: theme.spacing(1),
    },
    jumbo: {
        height: "100vh"
      },
  }));

export default function FoundSwag() {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query {
      jumbo: file(relativePath: { eq: "jumbotron-background.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  
    return (
        <Layout>
        <SEO title="Found Swag" />
        <BackgroundImage 
          Tag="section"
          className={classes.jumbo}
          fluid={data.jumbo.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          >
            <div class="jumbo-inner-wrapper-frankenstein">
                <div>
                  <h1 class="jumbo-title-wine">
                      Found Swag
                  </h1>
                  <h5 class="subtitle-wine">Grab Found Swag, Support Original Theater</h5>
                  <div class="wine-buttons">
                      <a title="Found Swag Store" href="https://gumroad.com/foundstages" class="button button--medium blackButton--border">
                          Browse the Store
                          </a>
                      {/* <a title="Find out more about Them!" href="https://www.nationaltheatrescotland.com/production/them/" class="button button--medium blackButton--border">
                          Frankenstein's Funeral
                          </a> */}
                </div>
                <form action="https://gumroad.com/follow_from_embed_form" class="form gumroad-follow-form-embed" method="post">
                    <div className="gumroad-input-wrapper">
                        <input name="seller_id" type="hidden" value="5309251452068"/> 
                        <input name="email" placeholder="Your email address" type="email"/> 
                    </div> 
                    <button data-custom-highlight-color="" type="submit">Follow Us on Gumroad</button> 
                </form>
                </div>
            </div>
        </BackgroundImage>
        {/* <div className="swag-body-wrapper">

        </div> */}

        
        </Layout>
    )
}
